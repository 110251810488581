import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";

// Initialize Firebase
const app = initializeApp(
  {
    apiKey: "AIzaSyDlBRau8xVPEwtlju4l3i27LoCot4W0D0k",
  authDomain: "panelaccess-59dca.firebaseapp.com",
  projectId: "panelaccess-59dca",
  storageBucket: "panelaccess-59dca.appspot.com",
  messagingSenderId: "878817582352",
  appId: "1:878817582352:web:994dcc3de3c1d23f4fd995",
  measurementId: "G-SGKZECPX5R"
  }
)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const messaging = getMessaging(app);