import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'


import Finance from "./pages/Finance";
import Firstsection  from './pages/panel';


import AboutUs from "./pages/AboutUs";

// Elements import Here 

import Contact from "./elements/contact/Contact";

import ServePage from "./pages/servicespage";
import BlogGridView from "./components/blog/BlogGridView";

import GallerPage from "./pages/Galleryy";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";
import { MaterialUIControllerProvider } from "./context";
import { AuthContextProvider } from "./context/AuthContext"
import { BrowserRouter } from "react-router-dom";

// Import Css Here 
import './assets/scss/style.scss';

function myCustomMethod() {
    return (
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <AuthContextProvider>
            <Firstsection />
          </AuthContextProvider>
        </MaterialUIControllerProvider>
      </BrowserRouter>
    );
  }
  
  const App = () => {
    const [isAdminPanel, setIsAdminPanel] = useState(false);

    useEffect(() => {
      if (window.location.pathname.includes('/admin') || window.location.pathname.includes('/login')) {
        setIsAdminPanel(true);
     

      } else {
   
        setIsAdminPanel(false);
      }
    }, []);

    return (
        <div>
        {isAdminPanel ? (
         myCustomMethod()
        ) : (
          <Router>
          <PageScrollTop>
            <Routes>
              <Route path="/" element={<Finance/>}/> 
              {/* Blog Part  */}
              <Route path="/haberler-duyurular" element={<BlogGridView/>}/>
              <Route path="/blog-detay/:id" element={<BlogDetails/>}/>
              <Route path="/iletisim" element={<Contact/>}/>
              <Route path="/tarihce" element={<AboutUs/>}/>
              <Route path="/galeri" element={<GallerPage/>}/>
              <Route path="/error" element={<Error/>}/>
              <Route path="/hizmetlerimiz" element={<ServePage/>}/>
              {/* Hata yönlendirmesi - Yukarıdaki yolların dışındaki herhangi bir yol için */}
              <Route path="/*" element={<Error />} />
            </Routes>
          </PageScrollTop>
        </Router>
        )}
      </div>

    )
}


export default App
