// @mui material components
import Grid from "@mui/material/Grid";

// Admin panel React components
import MDBox from "components/MDBox"
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

// Admin panel React example components
import * as React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Bill from "../data/salesDetailCard.js";

import { useParams } from "react-router-dom"




function Detail() {

  const [data, setData] = React.useState({
    id: 0,
    title: "",
    image: "",
    description: "",
  });  const { id } = useParams()

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://213.238.181.95:3000/getDetail?id=${id}&additionalParam=servisler`);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Fetch failed');
        }
        setData(data);
        setData(prevState => ({
          ...prevState,
          id: data[0].id,
          title:data[0].title,
          image: data[0].image,
          description:data[0].description,

        }));
      } catch (error) {
      } finally {
      }
    };

    fetchData(id);
  }, [id]);


  console.log("Outside useEffect - Current data:", data);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox pt={3} px={2}>
                    <MDTypography variant="h6" fontWeight="medium" sx={{ textAlign: 'center' }}>
                      Servis Detay
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                     {data && <Bill
                        id={data.id}
                        title={data.title}
                        description={data.description}
                        logo={`${process.env.PUBLIC_URL}/${data.image}`}
                      />}
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Detail;
