import React, { useState, useEffect } from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import GalleryOne from "./GalleryOne";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Slider from 'react-slick';
import jsonData from '../../data/blog/Image.json';




const Elements = () => {
   
    
    const [slidesToShow, setSlidesToShow] = useState(2);
  
    const responsiveSettings = [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ];
  
    const sliderSettings = {
      dots: true,
      infinite: false,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      responsive: responsiveSettings,
    };
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setSlidesToShow(1);
        } else if (window.innerWidth > 768) {
          setSlidesToShow(2);
        } else {
          // Büyük ekranlarda, slayt sayısını istediğiniz gibi ayarlayın
        }
      };
  
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return (
            <div className="main-content">
                    <div className="container">
                      
                        <div className="row mt_dec--30 row--15">
                        <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...sliderSettings}>
                       {jsonData.blog.map((item) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                            <div className="gallery-item-wrapper" style={{ padding: '10px' }}>

                              <GalleryOne galleryItem={item} />
                              </div>

                            </div>
                          ))}
                      </Slider>
                        </div>
           
                    </div>
            </div>
         
    );
};

export default Elements;
