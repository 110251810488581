import React, { useEffect } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import AboutThree from '../elements/about/AboutThree';
import ServiceOne from "../elements/service/ServiceOne";
import Elements from '../elements/gallery/Gallery';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import ServiceFive from '../elements/service/ServiceFive';

function ServePage  ()  {

    return (
        <>
            <SEO title="Servisler" />
            <main className="page-wrapper">
               
  <div>
  <HeaderTopBar />
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>
 
  
            {/* End Slider Area  */}
               {/* Start Service Area  */}
               <div className="rn-service-area"style={{ paddingTop: '80px' }}>
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12">
                           <SectionTitle
                               textAlign = "text-center"
                               radiusRounded = ""
                               subtitle = "Size için ne yapabiliriz ?"
                               title = "Hizmetlerimiz"
                               description = ""
                               />
                       </div>
                       <div style={{ textAlign: 'center' }}>
      <p>
Murat İnşaat olarak uluslararası denizyolu taşımacılığı alanında tecrübeli ve profesyonel kadromuz ile en zor ve en karmaşık görünen lojistik ihtiyaçlarınızı pratik yaklaşımlarımız ile tüm süreçlerinizi başarılı şekilde çözüme kavuşturuyoruz. Şirketimiz bünyesinde 120 işçi çalışmakta ve 45 Adet çeşitli tonajlarda (3 tonluk forkliftten 32 tonluk forklifte kadar) Heli marka forkliftimiz bulunmaktadır.
</p>
</div>
                       <ServiceFive 
                       serviceStyle = "gallery-style"
                       textAlign = "servicerecreate"
                   />
                   </div>
                 
           
               </div>
               </div>
    

                <FooterThree />
            </main>
        </>
    )
}

export default ServePage;
