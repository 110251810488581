// @mui material components
import Grid from "@mui/material/Grid";

// Admin panel React components
import MDBox from "components/MDBox"
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { CircularProgress, OutlinedInput, InputAdornment, IconButton, DialogActions, Dialog, DialogTitle, DialogContent, Typography, Box, TextField, InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import { green,red } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';

// Admin panel React example components
import React, { useState,useEffect } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";

// Data

import banksNameTable from "layouts/banks/data/banksNameTable";


import jsor from '../../data/blog/Image.json';

//modal Styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Brands() {


  const { columns, rows } = banksNameTable();
  const [brandsModal, setBrandsModal] = React.useState(false);
  const [brandsNotification, setBrandsNotification] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [imageProgress, setImageProgress] = React.useState(0);
  const [imageProgressValue, setImageProgressValue] = React.useState(0);


  const [file, setFile] = useState(null);
  const [imagenamee, simagenamee] = React.useState('');
  const [lonenlyimagename, Setlonenlyimagename] = React.useState('');

  const maxFileSizeInBytes = 100000000; // 5MB

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  
    if (selectedFile) {
      if (selectedFile.size <= maxFileSizeInBytes) {
        setFile(selectedFile);
        const directoryPath = process.env.PUBLIC_URL+"images/gallery/";
        const filename = selectedFile.name;
        const newFileName = filename.replace(/ğ/g, 'g').replace(/ü/g, 'u').replace(/ş/g, 's').replace(/ı/g, 'i').replace(/ö/g, 'o').replace(/ç/g, 'c').replace(/Ğ/g, 'G').replace(/Ü/g, 'U').replace(/Ş/g, 'S').replace(/I/g, 'I').replace(/Ö/g, 'O').replace(/Ç/g, 'C');
        const fullPath = directoryPath + newFileName;
        simagenamee(fullPath);
        Setlonenlyimagename(newFileName);
      } else {
        alert("Dosya çok büyük! Maksimum 100MB olmalı.");
      }
    }
  };

  const handleUpload = (degerdata) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('blog', 'gallery'); 
      formData.append('dataname',lonenlyimagename);
  
      fetch('https://mrtdenizcilik.com/upload', {
        method: 'POST',
        body: formData,
      })
      fetch(imagenamee)
      .then((response) => {
        if (response.status === 200) {
          // Burada veriyi sunucuya ekleyin, bu adımda yeni veriyi gönderin
           
        } else {
          alert('Resim yüklenemedi');
        }
      })

      .catch((error) => {
        alert('Bir hata oluştu: ' + error);
      });
      fetch('https://mrtdenizcilik.com/addData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          degerdata: degerdata,
          blog: 'gallery',
        })
      })
        .then((addDataResponse) => {
          if (addDataResponse.status === 200) {
            alert('Veri başarıyla eklendi');
            // Başka bir işlem yapabilirsiniz veya kullanıcıyı yönlendirebilirsiniz.
            brandsModalClose()
            window.location.reload();

          } else {
            alert('Veri eklenemedi');
          }
        })
        .catch((error) => {
          console.error('Bir hata oluştu: ' + error);
          alert('Veri eklenemedi'+error);

        });
  
    }
  };

  const bubberer=()=>{

    if (!file) {
      alert('Lütfen bir dosya seçin'); // Eğer file boşsa, uyarı göster
      return;
    }

    const blogData = jsor.blog;
    const dataCount = blogData.length+1;

    const yeniVeri = {
      id: dataCount,
      image:  imagenamee,
      popupLink:  imagenamee,


    };
  

    handleUpload(yeniVeri);
  }
  
  



 

  const brandsModalOpen = () => setBrandsModal(true);
  const brandsModalClose = () => {
    setBrandsModal(false)
    setLoading(false)
    setError('')
    setImageProgress(0)
    setImageProgressValue(0)
  };
  const brandsNotificationOpen = () => setBrandsNotification(true);
  const brandsNotificationClose = () => setBrandsNotification(false);
  return (
    <>
      <MDSnackbar
        color="success"
        icon="check"
        title="Successfully Add"
        // content="Hello, world! This is a brandsNotification message"
        // dateTime="11 mins ago"
        open={brandsNotification}
        onClose={brandsNotificationClose}
        close={brandsNotificationClose}
      />
      <BootstrapDialog
        onClose={brandsModalClose}
        aria-labelledby="customized-dialog-title"
        open={brandsModal}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={brandsModalClose}>
          <Typography variant="h3" color="secondary.main" sx={{ pt: 1, textAlign: "center" }}>Resim Ekle</Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 2, maxWidth: "100%", display: "flex", direction: "column", justifyContent: "center" },
            }}
            noValidate
            autoComplete="off"
          >
        
        
            <Box sx={{ maxWidth: "100%", m: 2 }}>
              <FormControl fullWidth sx={{ mt: 2 }} >
              

                <InputAdornment position="start" sx={{ marginTop: 2 }}>
            
                <input type="file" accept="image/*,video/*" onChange={handleFileChange} />
                  </InputAdornment>
             
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        size={25}
                        sx={{
                          color: green[500],
                        }}
                        value={imageProgress} />
                        <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {imageProgressValue === 100 ? <CheckIcon /> : null}
                      </Box>
                    </Box>

              </FormControl>
            </Box>
            {error === '' ? null :
              <MDBox mb={2} p={1}>
                <TextField
                  error
                  id="standard-error"
                  label="Error"
                  InputProps={{
                    readOnly: true,
                    sx: {
                      "& input": {
                        color: "red",
                      }
                    }
                  }}
                  // defaultValue="Invalid Data!"
                  value={error}
                  variant="standard"
                />
              </MDBox>}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          {loading ?
            <CircularProgress
              size={30}
              sx={{
                color: green[500],
              }}
            /> : <MDButton variant="contained" color="info" type="submit"
              // disabled={brandsData.name === '' || brandsData.contactNo === '' || brandsData.website === '' || brandsData.category === '' || brandsData.logo === '' ? true : false}
              onClick={bubberer}
            >Kaydet</MDButton>
          }
        </DialogActions>
      </BootstrapDialog>

      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDBox pt={2} pb={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h6" fontWeight="medium" color="white">
                        Tüm resimler
                      </MDTypography>
                      <MDButton variant="gradient" color="light"
                        onClick={() => {
                          brandsModalOpen()
                        }}>
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;RESİM EKLE
                      </MDButton>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Brands;
