// @mui material components
import Grid from "@mui/material/Grid";

//  admin panel React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";

import { Button, DialogContentText, DialogActions, Dialog, DialogTitle, DialogContent } from '@mui/material'

//  admin panel React example components
import React, { useState, useEffect} from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Typography, Paper, Divider, Stack, Tooltip, Avatar } from '@mui/material'
import jsonData from '../../data/blog/AllMessages.json';


function Notifications() {
  const [dbCarouselSale, setDbCarouselSale] = React.useState({})
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [data, setData] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 3; // Sayfa başına görüntülenen öğe sayısı
  const [mounted, setMounted] = useState(true); // Add a mounted state variable



  const fetchDataAndSetData = async () => {
    try {
      const response = await fetch('https://213.238.181.95:3000/getData?blog=customermessages');
      const jsonData = await response.json();

      // Reverse the fetched data
      const reversedData = jsonData.slice().reverse();

      // Set the reversed data
      setData(reversedData);

      // Display the initial items
      const initialItems = reversedData.slice(0, itemsPerPage);
      setVisibleItems(initialItems);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchDataAndSetData();

    // Cleanup function to set 'mounted' to false when the component is unmounted
    return () => {
      setMounted(false);
    };
  }, []); // 

  
  const loadMore = () => {
    const nextPage = page + 1;
    const start = (nextPage - 1) * itemsPerPage;
    const end = nextPage * itemsPerPage;
    const newItems = data.slice(start, end);

    if (newItems.length > 0) {
      setPage(nextPage);
      setVisibleItems([...visibleItems, ...newItems]);
    }
  };
  const deleteAlertOpen = (index) => {


 setDbCarouselSale(index);
 setDeleteAlert(true);
  }
  const deleteAlertClose = () => setDeleteAlert(false);
  const onDeleteCarousels = () => {
    const formData = new FormData();
  
    formData.append('id', dbCarouselSale);
    formData.append('name', 'logo');
    formData.append('blog', 'customermessage'); 

      fetch('https://213.238.181.95:3000/deleteData', {
        method: 'POST',
        headers: {
        },
        body: formData,
      })
      .then((addDataResponse) => {
        if (addDataResponse.status === 200) {
          alert('Veri başarıyla silindi');
          
          deleteAlertClose();
          fetchDataAndSetData();
          //window.location.reload();

        } else {
          alert('Veri silinemedi');
        }
      })
      .catch((error) => {
        console.error('Bir hata oluştu: ' + error);
        alert('Veri silindi'+error);
        

      });
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div class="boxe">
      <Dialog
     open={deleteAlert}
     onClose={deleteAlertClose}
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
     BackdropProps={{ invisible: false }} 
   >
     <DialogTitle id="alert-dialog-title">
       {"Alert"}
     </DialogTitle>
     <DialogContent>
       <DialogContentText id="alert-dialog-description">
        Silmek istediğinize eminmisiniz ?
       </DialogContentText>
     </DialogContent>
     <DialogActions>
       <Button onClick={deleteAlertClose}>Cancel</Button>
       <Button sx={{ color: 'error.main' }} onClick={onDeleteCarousels}>
         Sil
       </Button>
     </DialogActions>
   </Dialog></div>
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9} lg={12} mx={'auto'}>

        
              <Paper elevation={5} sx={{
                bgcolor: 'black',
                margin: "0, auto",
                borderRadius: "1.5rem",
                width: '100%',
                p: 2
              }} >
                <Typography variant="h3" color="secondary.main" sx={{ pt: 2, textAlign: "center" }}>Son Mesajlar</Typography>
                <Divider />
                {visibleItems.map((items, index) => (
                  <>
                  
                    <div class="wrapper">
                    <div class="user-card">
                        <div class="user-card-info">
                          <h2>{items.isim}</h2>
                          <p class= "wibrax"><span>Email:</span> {items.email}</p>
                          <p class= "wibrax"><span>Telefon:</span> {items.telno}</p>
                          <p class= "wibrax"><span>Ip:</span> {items.ip}</p>
                          <p class= "wibrax"><span>Tarih:</span> {items.date}</p>
                          <p class= "wibrax"><span>Saat:</span> {items.saat}</p>
                          <p class= "wibrax"><span>Konu:</span> {items.konu}</p>
                          <p class= "wibrax"><span>Mesaj:</span>{items.message} </p>

                          <div class="divpad"><MDButton className="trybutton"  color="error" onClick={() => deleteAlertOpen(items.id)}>
                          <Icon>delete</Icon>&nbsp;SİL
                           </MDButton></div>
                        </div>
                    </div>

                   
                </div>
                  
               
                  </>
                ))}
                {visibleItems.length < jsonData.blog.length && (
                  <div className="text-center">
                  <div className="rwt-load-more text-center mt--50">
  
                      <button onClick={loadMore} className="btn btn-default btn-large btn-icon">Daha fazla</button>
                      </div>
  
                  </div>
              )}
              </Paper>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Notifications;
