import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';


const ServiceFive = ({ textAlign, serviceStyle }) => {
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://213.238.181.95:3000/getData?blog=service'); // API veya servisinizin gerçek adresini kullanın
        if (!response.ok) {
          throw new Error('Veriler getirilemedi.');
        }
        const data = await response.json();
        setServiceList(data);
      } catch (error) {
        console.error('Veri getirme hatası:', error.message);
      }
    };

    fetchData();
  }, []); 

  return (
    <div>
      <div className="row row--15 service-wrapper">
        {serviceList.map((val, i) => (
          <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
              <div className={`service ${serviceStyle} ${textAlign}`}>
                <div className="inner">
                  <div className="content">
                    <h4 className="title">
                      <div className="xddw" dangerouslySetInnerHTML={{ __html: val.title }}></div>
                    </h4>
                  </div>
                  <p className="description wibrax" dangerouslySetInnerHTML={{ __html: val.description }}></p>
                  <div className="image">
                    <img className='newstyleimg' src={`${val.image}`} alt="card Images" />
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceFive;
