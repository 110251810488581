import React, { useState, useEffect, useLayoutEffect } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../breadcrumb/BreadcrumbOne';
import SectionTitle from '../sectionTitle/SectionTitle';
import GalleryOne from './GalleryOne';

const ElementsBig = () => {
  const [slidesToShow, setSlidesToShow] = useState(2);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [galleryData, setGalleryData] = useState([]);
  const [galleryDataCount, setGalleryDataCount] = useState(6);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [infinite, setInfinite] = useState(true);
  const reversedData = '';


  const responsiveSettings = [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: infinite,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    responsive: responsiveSettings,
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      if (window.innerWidth < 768) {
        setSlidesToShow(1);
      } else if (window.innerWidth > 768) {
        setSlidesToShow(Math.floor((viewportWidth * 80) / 100 / 300));
      }
    };

    const handleScroll = () => {
      const scrollHeight = document.body.scrollHeight - window.innerHeight;
      const currentScroll = window.scrollY;

      const percentage = (currentScroll / scrollHeight) * 100;
      setScrollPercentage(percentage);

      if (percentage >= 80 && infinite) {
        handleSliderLoadMore();
      } else if (percentage >= 40 && infinite) {
        // You can add additional conditions for different scroll percentages
        // Example: handleSliderLoadMoreFaster();
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [infinite, scrollPercentage, viewportWidth]);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://213.238.181.95:3000/getData?blog=galle');
        const data = await response.json();
        console.log(JSON.stringify(data));
        const reversedData = data.reverse();
   
        setGalleryData(reversedData.slice(0, galleryDataCount));
        setTotalDataCount(data.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [galleryDataCount]);

  const handleSliderLoadMore = () => {
    if (galleryDataCount < totalDataCount) {
      const newDataSlice = reversedData.slice(
        galleryDataCount,
        galleryDataCount + 6
      );

      setGalleryData((prevData) => [...prevData, ...newDataSlice]);
      setTotalDataCount((prevCount) => prevCount + newDataSlice.length);

      if (galleryDataCount + 6 >= totalDataCount) {
        setInfinite(false);
      }

      setGalleryDataCount((prevCount) => prevCount + 6);
    }
  };

  return (
    <div className="main-content">
      <div className="container">
        <div className="row mt_dec--30 row--15">
          <div className="col-lg-12">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle="Galeri"
              title="Galeri"
              description=""
            />
          </div>
          {galleryData.map((item) => (
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-12 mt--30"
              key={item.id}
            >
              <div
                className="gallery-item-wrapper"
                style={{ padding: '10px' }}
              >
                <GalleryOne galleryItem={item} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ElementsBig;
