import React, { useState, useEffect } from "react";
import BlogList from './BlogList';
import { FaSpinner } from "react-icons/fa";
import filters from "./Filters";


const BlogProp = ({ column, StyleVarProp }) => {
    const [getAllItems, setGetAllItems] = useState([]);
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [dataIncrement] = useState(3);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);
    useEffect(() => {
        setActiveFilter(filters[0].text.toLowerCase());
        window.addEventListener("scroll", handleScroll);
    
        fetchData(); // Veriyi ilk yükleme
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
    
      const fetchData = async () => {
        try {
          const response = await fetch("https://213.238.181.95:3000/getData?blog=blog"); // API endpointinizi buraya ekleyin
          const result = await response.json();
          setGetAllItems(result.slice()); // Veriyi çekip ters çevirme
          setVisibleItems(result.slice(0, dataVisibleCount));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            document.documentElement.offsetHeight
        ) {
            // Sayfanın sonuna gelindiğinde yeni veri yükle
            handleLoadmorebl();
        }
    };

    const handleLoadmorebl = () => {
        let tempCount = dataVisibleCount + dataIncrement;
        if (dataVisibleCount >= getAllItems.length) {
            // Tüm veriler görüntülendi, yeni veri eklenmiyor
            return;
        } else {
            setDataVisibleCount(tempCount);
            setVisibleItems(getAllItems.slice(0, tempCount));
        }
    };

    return (
        <>
            <div className="col-lg-12">
                <div className="row row--15">
                    {visibleItems.map((item) => (
                        <div key={item.id} className={column}>
                            <BlogList StyleVar={StyleVarProp} data={item} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-lg-12 text-center">
                <div className="rwt-load-more text-center mt--60">
                    <span className="icon">
                    </span>
                </div>
            </div>
        </>
    )
}

export default BlogProp;
