import React, { useEffect } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';

import SectionTitle from "../elements/sectionTitle/SectionTitle";

import CompanyInfo from '../elements/gallery/Componyİnfoo';
import BrandThree from '../elements/brand/BrandThree';  
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

function AboutUs  ()  {

    return (
        <>
            <SEO title="Hakkımızda" />
            <main className="page-wrapper">
               
  <div>
  <HeaderTopBar />
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                <div className="container">
                <div className="text-center">
                <h1 className="rn-sub-badge"><span className="theme-gradient">Hakkımızda</span></h1>

                </div>
                    <div className="row">
                    <div className="inner pt--40 text-center">
                    <div>
                    </div>
                </div>
                        <div className="col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div className="inner text-start">
                                <h1 className="title theme-gradient display-two">Misyonumuz<br />  {" "}
                                    <Typed
                                        strings={[
                                            "Güvenilirlik.",
                                            "Profesyonellik.",
                                            "Kusursuzluk.",
                                            "Memnuniyet .",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h1>

                                <span className="subtitle">MRT Port Services</span>
                                <div className="button-group pt--40">
                                    <Link className="btn-default btn-medium btn-border round btn-icon " to="/iletisim">İletişim <i className="icon"><FiArrowRight /></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 order-1 order-lg-2">
                            <div className="thumbnail">
                                <img src="./images/bg/blueback.png" alt="Banner Images" />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <SectionTitle
                        textAlign = "text-center"
                        radiusRounded = ""
                        subtitle = "Biz kimiz?"
                        title = "Tarihçe"
                        description = ""
                        />
                </div>
                <CompanyInfo></CompanyInfo>
            </div>
          
        </div>
        <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Bizi tercih edenler"
                                        title = "Markalar"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandThree brandStyle="brand-style-2 variation-2" />
                                </div>
                            </div>
                        </div>
                    </div>
  
     
                <FooterThree />
            </main>
        </>
    )
}

export default AboutUs;
