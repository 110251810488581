// Admin panel React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Icon } from "@mui/material";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import * as React from "react"
import { Button, DialogContentText, DialogActions, Dialog, DialogTitle, DialogContent } from '@mui/material'

// Admin panel React context
import { useMaterialUIController } from "context";
import jsonData from '../../../data/blog/Image.json';


function Data() {
  const [controller] = useMaterialUIController();
  const [data, setData] = React.useState([])
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [imagename, setsimagename] = React.useState('');
  const [dbCarouselSale, setDbCarouselSale] = React.useState('');
  const [delName, SetdelName] = React.useState('');


  

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://mrtdenizcilik.com/getData?blog=galle');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();
        const reversedData = jsonData.reverse();
        setData(reversedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };  

    fetchData();
  }, []); // Boş dependency array, sadece ilk render'da çalışması için

  const onDeleteCarousels = () => {
    if (dbCarouselSale) {
      const formData = new FormData();
      formData.append('id', dbCarouselSale);
      formData.append('name', delName);
      formData.append('blog', 'gallery'); 
  
      fetch('https://mrtdenizcilik.com/deleteData', {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json', // Content-Type'ı FormData kullanırken kaldırın
        },
        body: formData,
      })
        .then((addDataResponse) => {
          if (addDataResponse.status === 200) {
            alert('Veri başarıyla silindi');
            // Başka bir işlem yapabilirsiniz veya kullanıcıyı yönlendirebilirsiniz.
            deleteAlertClose()
            window.location.reload();

          } else {
            alert('Veri silinemedi');
          }
        })
        .catch((error) => {
          console.error('Bir hata oluştu: ' + error);
          alert('Veri silinemedi'+error);
          

        });
      
   
  }
}
  function cutpath(veri)
  {
    const parts = veri.split('/');
    const innermostDirectory = parts.pop();
    setsimagename(innermostDirectory);
  }

  const SR_NO = ({ srNo }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography variant="body2" fontWeight="small">
          {srNo}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const BRAND_NAME = ({ image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={`${process.env.PUBLIC_URL}/${image}`} name={""} size="xxl" />
      <MDBox ml={2} lineHeight={1}>
    
      </MDBox >
    </MDBox>  
  );

  const deleteAlertOpen = (index,name) => {
    setDeleteAlert(true);

setDbCarouselSale(index);
SetdelName(name);
  }
  const deleteAlertClose = () => setDeleteAlert(false);
  return {
    columns: [
      { Header: "SR NO#", accessor: "srNo", width: '10%', align: "left" },
      { Header: "RESIMLER", accessor: "brand", align: "left" },
      { Header: "Action", accessor: "action", align: "right" }

    ],
    rows: [...data.map((items, index) => {
      

      return ({
        srNo: <SR_NO srNo={items.id} />,
        brand: <BRAND_NAME image={items.image} />,
        action: (<>
          <div class="boxe">
          <Dialog
            open={deleteAlert}
            onClose={deleteAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            BackdropProps={{ invisible: false }} 
          >
            <DialogTitle id="alert-dialog-title">
              {"Alert"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
               Silmek istediğinize eminmisiniz ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deleteAlertClose}>Cancel</Button>
              <Button sx={{ color: 'error.main' }} onClick={onDeleteCarousels}>
                Sil
              </Button>
            </DialogActions>
          </Dialog>
          </div>
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
           
            <MDBox mr={1}>
              <MDButton variant="text" color="error" onClick={() => deleteAlertOpen(items.id,items.image)}>
                <Icon>delete</Icon>&nbsp;SİL
              </MDButton>
            </MDBox>
          </MDBox></>),
      })
    })]
  }
}
export default Data