// Amdin panel React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import * as React from "react"

//firestore


function Data() {
  const [data, setData] = React.useState([])


  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://213.238.181.95:3000/getData?blog=service');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();
        const reversedData = jsonData.reverse();
        setData(reversedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Boş dependency array, sadece ilk render'da çalışması için

  const SR_NO = ({ srNo }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography variant="body2" fontWeight="small">
          {srNo}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const BRAND_NAME = ({ name, image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={`${process.env.PUBLIC_URL}/${image}`} name={name} size="xxl" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium" sx={{textTransform: 'capitalize'}}>
          {name}
        </MDTypography>
      </MDBox >
    </MDBox>

  );

  return {
    columns: [
      { Header: "SR NO#", accessor: "srNo", width: '10%', align: "left" },
      { Header: "SERVİS", accessor: "brands", align: "left" },
      { Header: "Action", accessor: "action", align: "right" }
    ],
    rows: [...data.map((items, index) => {
      return ({
        srNo: <SR_NO srNo={items.id} />,
        brands: <BRAND_NAME image={items.image} name={items.title} />,
        action: <Link to={`/admin/servisler/detay/${items.id}`}><MDButton variant="gradient" color="info" size="small">Detay</MDButton></Link>,
      })
    })]
  }
}
export default Data