import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Anasayfa</Link>
                
            </li>
            
            <li className="has-droupdown"><Link to="/hizmetlerimiz">Hizmetlerimiz</Link></li>     

            <li ><Link to="/haberler-duyurular">Haberler-Duyurular</Link>
          
            </li>
            <li className="has-droupdown"><Link to="/galeri">Galeri</Link></li>


            <li className="has-droupdown"><Link to="/tarihce">Hakkımızda</Link></li>

            <li className="has-droupdown"><Link to="/iletisim">İletişim</Link></li>
        </ul>
    )
}
export default Nav;
