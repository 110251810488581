import React, { useEffect, useState } from 'react';
import { FiUser, FiCalendar } from "react-icons/fi";


function BlogDetailsContent({ id }) {
    const [newData, setData] = React.useState({
        id: 0,
        title: "",
        body: "",
        date: "",
        image: "",
        // diğer alanları da ekleyin
      });
    
      React.useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`https://213.238.181.95:3000/getDetail?id=${id}&additionalParam=haberler`);
            const data = await response.json();
    
            if (!response.ok) {
              throw new Error(data.error || 'Fetch failed');
            }
    
              setData(prevState => ({
                ...prevState,
                id: data[0].id,
                title:data[0].title,
                body:data[0].body,
                date:data[0].date,
                image: data[0].image,
    
              }));
       
    
    
          } catch (error) {
          } finally {
          }
        };
    
        fetchData();
      }, [id]);

  return (
    <>
      <div className="post-page-banner rn-section-gapTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content text-center">
                <div className="page-title">
                  <h1 className="theme-gradient">{newData.title}</h1>
                </div>
                <ul className="rn-meta-list">
                  <li><FiCalendar />{newData.date}</li>
                </ul>
                <div className="thumbnail trywokr ">
                  <img className="w-100 radius" src={`${process.env.PUBLIC_URL}/${newData.image}`} alt="Blog Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-content pt--60 rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
            <div className="content" dangerouslySetInnerHTML={{ __html: newData.body}} />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogDetailsContent;
