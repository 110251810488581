import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import SEO from '../common/SEO';
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import BlogClassicData from '../data/blog/BlogList.json';

var BlogListData = BlogClassicData.slice(0, 3);

function Finance() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [srcc, Sersrc] = useState(`${process.env.PUBLIC_URL}/images/bg/backvideomobile.mp4`);


  const videoRef = useRef();
  const redirectToTarihce = () => {
    window.location.href = '/tarihce'; // Yeni sayfanın URL'sini belirtin
  };

  useEffect(() => {
    function handleWindowResize() {
      const newWindowSize = getWindowSize();
      setWindowSize(newWindowSize);
      if (newWindowSize.innerWidth <= 750) {
        videoRef.current.src = `${process.env.PUBLIC_URL}/images/bg/porttt.mp4`;
        Sersrc(`${process.env.PUBLIC_URL}/images/bg/porttt.mp4`)

      }
      else {

        videoRef.current.src = `${process.env.PUBLIC_URL}/images/bg/porttt.mp4`;
        Sersrc(`${process.env.PUBLIC_URL}/images/bg/porttt.mp4`)


      }
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  return (
    <>
      <SEO title="Finance" />
      <main className="page-wrapper" style={{height:"100vh"}}>
        <div>
          <HeaderTopBar />
          <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
        </div>
        <div className={`slider-area slider-style-5 ${windowSize.innerWidth <= 750 ? 'bg-overlay-soliddd' : 'bg-overlay-solid'} height-850 background-video`} data-black-overlay="3">
        <video ref={videoRef} autoPlay playsInline muted loop>
    <source
        src={`${process.env.PUBLIC_URL}/images/bg/porttt.mp4`}
        type="video/mp4"
    />
</video>
          <div className="vracontainer">
            <div className="row">
              <div className="col-lg-3" >
                <div className="inner text-start">
                  <h4 className="subtitle">KEŞFET, ÖZÜMSE, İLERLE !</h4>
                  <h1 className="headfont">
                    <img
                      src="/images/bg/yanresim.png" // Resminizin path'i
                      alt="Logo"
                      className="logo-image"
                    />
                    MRT
                    <h1 className="handwrite">Port Services</h1>
                    <h1 className="handwritesubtext">
                      Murat İnşaat Gümrükleme, Lashing ve Liman Hizmetleri, köklü bir denizcilik
                      tarihine sahip bir şirkettir. Şirket, 20. yüzyılın başlarına dayanan
                      kökleriyle denizcilik sektöründe önemli bir yere sahiptir.
                    </h1>
                  </h1>

                </div>
              </div>
            </div>
            <div className="button-group mt--40 mt_sm--20">
              <button className='glowing-btn'>
                <Link style={{color: 'rgba(255, 255, 255, 0.8)'}} to='/tarihce'>
                  <span className='glowing-txt'>
                    K<span className='faulty-letter'>E</span>ŞFET
                  </span>
                </Link>
              </button>            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Finance;

