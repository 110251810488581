import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogProp from './itemProp/BlogProp';
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import HeaderTopBar from '../../common/header/HeaderTopBar';
import HeaderOne from '../../common/header/HeaderOne';
import FooterThree from '../../common/footer/FooterThree';

const BlogGridView = () => {
    return (
        <>
            <SEO title="Haberler-Duyurular" />
            
  <div>
  <HeaderTopBar />
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />

</div>
    
                <div className="main-content">
                    
              
                    
                    <div className="rn-blog-area rn-section-gap">
              
                    
                        <div className="container">
                        
                            <div className="row mt_dec--30">
                            <SectionTitle
                            textAlign = "text-center"
                            radiusRounded = ""
                            subtitle = "Haberler-Duyurular"
                            title = "Son haberler"
                            description = ""
                            />
                                <BlogProp column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
                            </div>
                        </div>
                    </div>
                </div>
                <FooterThree />

        </>
    )
}
export default BlogGridView;