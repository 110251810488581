import React from 'react';
import ContactForm from "./ContactForm";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Telefon numaramız</h4>
                                        <p><a href="tel:+90 212 875 86 04">+90 212 875 86 04</a></p>
                                        <p><a href="tel:+90 532 653 84 76">+90 532 653 84 76</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Adresimiz</h4>
                                        <p><a href="muhammed@muratgumrukleme.com">muhammed@muratgumrukleme.com</a></p>
                                     
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Adresimiz</h4>
                                        <p>Yakuplu Merkez Mah, Fatih Sultan Mehmet Cad, Menekşe İş Merkezi No: 3/4 <br /> Beylikdüzü / İSTANBUL</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2129.6780435618043!2d28.666114961786647!3d40.98543152736805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55ff4b9a4409d%3A0xee7c683031bea13d!2zTWVuZWvFn2UgxLDFnyBNZXJrZXo!5e0!3m2!1str!2str!4v1700638197884!5m2!1str!2str"
                width="100%"
                height="550px"
                style={{ border: 0, boxShadow: '0 0 20px rgba(5, 157, 255, 1)',borderRadius: '10px'}}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
</div>
            </div>
        </>
    )
}
export default ContactOne;