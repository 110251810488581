import React from 'react';

const CompanyInfo = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <p>
        1985 Yılında Haydarpaşa Limanında Murat Otomotiv San. Ve Tic. Ltd. Şti. ünvanı ile
        tahmil-tahliye ve Lashing hizmetleri vermeye başlayan şirketimiz, 1998 Yılından beri Ambarlı
        Limanında, Murat ins. Ve Gümrükleme Nakliyat Hizmetleri Ve Tic. Ltd. Şti. olarak, Marport
        Liman İşletmeleri San. Ve Tic. A.Ş. bünyesinde taşeron olarak hizmet vermeye devam etmektedir.
        Yaklaşık 40 yıldır aynı sektörde başarı ile hizmet veren şirketimiz kalifiye insan kaynağı,
        yenilenen iş makinaları, geniş iş hacmini destekleyen sistemi ve gelişmiş operasyonel
        uzmanlığı ile müşterilerine hızlı, kaliteli ve güvenilir taşımacılık çözümleri sunmaktadır.
       2024 Yılında yeni kurumsal logomuz ve ismimiz olan Mrt Port Services unvanı ile hizmet vermeye devam edeceğiz.</p>

      <p>
        <strong>Vizyonumuz</strong>
        <br />
        Yüksek standartlarda Liman hizmetleri sunmaya devam edebilmek için sürekli olarak kendimizi, işçimizi, makina
        parkurumuzu yenilemek ve çağın gereklerine ayak uydurabilmektir. Müşterilerimize günümüz koşullarına uygun,
        hızlı, kaliteli hizmet vermek ve bu vesileyle daha fazla iş üreten bir firma olmaktır.
      </p>
      <br />
      <p>
        <strong>Misyonumuz</strong>
        <br />
        Müşteri beklentilerine göre çözüm üretir, onların memnuniyetine göre kendimizi ve işimizi geliştiririz.
        Çalışanlarımızı uzun yıllar bünyemizde tutmak için özverili davranırız. Güvenli ve sağlıklı bir ortamda
        çalışmaları için gayret sarf ederiz. Bu nedenle yanımızda hala 20 yıldan fazla çalışan personellerimiz
        bulunmaktadır. Bu da firmamızın iş bilgisi, birikim ve tecrübeye verdiği önemi göstermektedir.
      </p>
    </div>
  );
};

export default CompanyInfo;
